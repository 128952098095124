<!--
This view displays which names are the most popular.
a prop name maps comes as is:
"maps": [
          {
            "islandCode": "1760-0152-1306",
            "name": "TILTED ZONE WARS (ALL WEAPONS) ⭐",
            "author": "PrettyBoyyAM",
            "image": "https://cdn-0001.qstv.on.epicgames.com/HCEqtcwuoiAFNdODTG/image/screen_comp.jpeg",
            "playerCount": 5576,
            "tags": ["deathrun","parkour","just for fun","difficulty: easy"]
        },
        ...
]
2) split the name into individual words and store the name parts in the nameParts property
3) for each name part accumulate the number of maps with that name part.
-->
<template>
  <div>
    <h2>Popular Name Parts per map count</h2>
    <b-table striped hover :items="nameParts" :fields="fields" :per-page="showMore?0:10" no-provider-paging></b-table>
    <!-- add button to show more -->
    <b-button @click="showMore = !showMore">Show More</b-button>
    <trend-tags :maps="mapsModified" field="nameParts"></trend-tags>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('trend-names');

export default {
  name: 'PopularTags',
  components: {
    TrendTags: () => import('./TrendTags.vue')
  },
  props: {
    maps: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      mapsModified: [],
      nameParts: [],
      fields: [
        { key: 'word', label: 'Word' },
        { key: 'count', label: 'Count' }
      ],
      showMore: false,
    }
  },
  watch: {
    maps() {
      this.updateChart();
    }
  },
  mounted() {
    log.log('mounted');
    this.updateChart();
  },
  methods: {
    updateChart() {
      this.calculateNameParts();
    },
    calculateNameParts() {
      this.mapsModified = this.maps.map(map => {
        // extract words and emoijs from the name
        let nameParts = map.name.match(/[\w\d]+|[\uD800-\uDBFF][\uDC00-\uDFFF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]/g);
        if (!nameParts) {
          // log.log('map has no words or emojis in name', map.name);
          nameParts = [];
        }        // bring to lower case
        nameParts.forEach((namePart, index) => {
          nameParts[index] = namePart.toLowerCase();
        });
        // log.log('nameParts', nameParts, map.name);
        return {
          ...map,
          nameParts
        }
      });      
      // counts the number of times each name part appears in the maps
      this.nameParts = this.mapsModified.reduce((acc, map) => {
        map.nameParts.forEach(namePart => {
          const index = acc.findIndex(item => item.word === namePart);
          if (index === -1) {
            acc.push({
              word: namePart,
              count: 1
            });
          } else {
            acc[index].count++;
          }
        });
        return acc;
      }, []);
      // sort the name parts by count
      this.nameParts.sort((a, b) => b.count - a.count);
      // log.log('nameParts', nameParts);
    },
  }
}
</script>


